.ErrorBoundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__reload {
        width: auto;
        min-width: auto;
        margin-top: 16px;
    }
}
