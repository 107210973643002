@import 'style/global-config';

.AuthWrapper {
    background-color: #212121;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
        flex-direction: column;
    }

    &__leftColumn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100%;
        background-color: #212121;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 800px) {
            flex: auto;
            padding: 8px;
        }
    }

    &__rightColumn {
        @include scroll-bar;
        flex: 1;
        border-top: 5px solid palette(ruby, 600);
        overflow: auto;

        @media (max-width: 800px) {
            flex: 0 0 auto;
        }
    }

    &__logo {
        width: 120px;
        margin: 0 auto;
        margin-bottom: 56px;

        @media (max-width: 400px) {
            margin-bottom: 31px;
        }
    }

    &__rightColumnContentWrapper {
        height: fit-content;
        padding: 32px;
        background: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        margin: 0 auto;
        border-radius: 8px;
        width: 394px;

        @media (max-width: 400px) {
            width: 264px;
        }
    }
}

.aa-Popup__footer {
    justify-content: center;
}

.aa-OrderedList__number {
    min-width: 26px;
    min-height: 24px;
}
