@import 'style/global-config';

.Error {
    &__container {
        padding: 8px 32px;
    }

    &__blockWrapper {
        width: 640px;
    }

    &__errorMessage {
        color: palette(carbonado, 600)
    }
}