@import './global-config';

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

html,
body {
    width: 100%;
    height: 100%;
}

body {
    @include scroll-bar;
    overflow: overlay;
    font-family: $preferred-font;
    height: 100%;
}

/* stylelint-disable-next-line selector-max-id */
#application_root {
    height: 100%;
}

div:focus,
span:focus,
input:focus {
    outline: none;
}

b {
    font-weight: bold;
}

u {
    text-decoration: underline;
}

// move to atol-atoms styles
.aa-ButtonSelector__dragContainer {
    cursor: move;
}

.aa-Row {
    margin-left: -10px !important;
    margin-right: -10px !important;
}
