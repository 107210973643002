.appLoader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.appAuthError {
    // background-image: url('../../images/connect-background.jpg');
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &__block {
        width: 330px;
        display: flex;
        flex-direction: column;
    }
}
