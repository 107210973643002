@font-face {
    font-family: 'CoFo Sans';
    src:
        url('./CoFoSans-Regular.woff2') format('woff2'),
        url('./CoFoSans-Regular.woff') format('woff'),
        url('./CoFoSans-Regular.ttf') format('truetype');
    font-weight: 400,
}

@font-face {
    font-family: 'CoFo Sans';
    src:
        url('./CoFoSans-Medium.woff2') format('woff2'),
        url('./CoFoSans-Medium.woff') format('woff'),
        url('./CoFoSans-Medium.ttf') format('truetype');
    font-weight: 500,
}

@font-face {
    font-family: 'CoFo Sans';
    src:
        url('./CoFoSans-Bold.woff2') format('woff2'),
        url('./CoFoSans-Bold.woff') format('woff'),
        url('./CoFoSans-Bold.ttf') format('truetype');
    font-weight: bold;
}
