@import 'style/global-config';

.LoginForm {
    width: 100%;

    &__Alert {
        span {
            margin-top: 0;
        }
    }

    &__buttonsWrapper {
        text-align: center;

        .aa-Button {
            display: inline;
        }

        .wide {
            width: 100%;
        }
    }

    &__DemoButton {
        background-color: #1ed54e;
        padding: 8px 16px;
        font-size: 12px;

        & svg {
            width: 12px;
            height: 12px;
        }

        &:hover {
            background-color: #08be3f;
        }

        &:active {
            background: #039732;
        }
    }
}
