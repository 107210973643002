@font-face {
    font-family: 'CourierNewPSMT';
    src:
        url('./CourierNewPSMT.woff2') format('woff2'),
        url('./CourierNewPSMT.woff') format('woff'),
        url('./CourierNewPSMT.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Courier New';
    src:
        url('./CourierNewPSMT.woff2') format('woff2'),
        url('./CourierNewPSMT.woff') format('woff'),
        url('./CourierNewPSMT.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
