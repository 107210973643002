@font-face {
    font-family: Helvetica;
    font-weight: 300;
    src:
        url('./HelveticaLight/HelveticaLight.woff') format('woff'),
        url('./HelveticaLight/HelveticaLight.ttf') format('truetype'),;
}

@font-face {
    font-family: Helvetica;
    font-weight: 300;
    font-style: italic;
    src:
        url('./HelveticaLightItalic/HelveticaLightItalic.woff') format('woff'),
        url('./HelveticaLightItalic/HelveticaLightItalic.ttf') format('truetype'),;
}

@font-face {
    font-family: Helvetica;
    font-weight: 400;
    src:
        url('./HelveticaRegular/HelveticaRegular.woff') format('woff'),
        url('./HelveticaRegular/HelveticaRegular.ttf') format('truetype'),;
}

@font-face {
    font-family: Helvetica;
    font-weight: 400;
    font-style: italic;
    src:
        url('./HelveticaItalic/HelveticaItalic.woff') format('woff'),
        url('./HelveticaItalic/HelveticaItalic.ttf') format('truetype'),;
}

@font-face {
    font-family: Helvetica;
    font-weight: 500;
    src:
        url('./HelveticaMedium/HelveticaMedium.woff') format('woff'),
        url('./HelveticaMedium/HelveticaMedium.ttf') format('truetype'),;
}


@font-face {
    font-family: Helvetica;
    font-weight: 500;
    font-style: italic;
    src:
        url('./HelveticaMediumItaicl/HelveticaMediumItaicl.woff') format('woff'),
        url('./HelveticaMediumItaicl/HelveticaMediumItaicl.ttf') format('truetype'),;
}

@font-face {
    font-family: Helvetica;
    font-weight: 700;
    src:
        url('./HelveticaBold/HelveticaBold.woff') format('woff'),
        url('./HelveticaBold/HelveticaBold.ttf') format('truetype'),;
}


@font-face {
    font-family: Helvetica;
    font-weight: 700;
    font-style: italic;
    src:
        url('./HelveticaBoldItalic/HelveticaBoldItalic.woff') format('woff'),
        url('./HelveticaBoldItalic/HelveticaBoldItalic.ttf') format('truetype'),;
}
