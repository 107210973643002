@import 'style/global-config';


// for christmas logo
// .aa-Menu__logo {
//     background-color: #fff;
//     border-bottom: 1px solid palette(carbonado, 100);
// }

// .aa-Menu__logoTitle {
//     color: palette(carbonado, 900);
// }

.LeftPanel {
    height: 100vh;
    position: sticky;
    top: 0;
    z-index: 100;
    @media screen and (max-width: $mobile-breakpoint) {
        width: 270px;
        position: fixed;

        &.aa-Menu_invisible {
            transition: visibility 0s linear 0.3s;
            visibility: hidden;
        }
    }

    &__copyright {
        font-size: 12px;
        margin-top: 16px;
    }

    &__GroupItem .aa-GroupItem__content {
        cursor: default;
    }

    &__noWrap {
        white-space: nowrap;
    }

    & .aa-GroupItem_active .aa-GroupItem__contentTitle {
        color: $palette-white;
        fill: $palette-white;
    }

    &__betaMark {
        margin-left: 4px;
        line-height: 1;
    }
}

.MenuOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);

    @media screen and (min-width: ($mobile-breakpoint + 1)) {
        display: none;
    }
}
