@import 'style/global-config';

.Wizard {
    display: flex;
    justify-content: center;

    &__content {
        flex-grow: 1;
        max-width: 560px;

        @media (max-width: $mobile-breakpoint) {
            min-width: 100%;
        }
    }

    &__loader {
        min-height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .aa-Popup__content {
        margin: 0;
    }

    &_onPage {
        background: $palette-white;
        box-shadow: 6px 6px 18px 0 rgba(54, 71, 79, 0.08);
        border-radius: 8px;
        margin: 0 auto;
        min-width: 560px;
        max-width: 560px;

        @media (max-width: 560px) {
            min-width: calc(100% - 16px);
        }
    }
}

.Error {
    &__errorList {
        text-align: left;
    }

    &__item {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
