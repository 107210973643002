@import 'style/global-config';

.TopPanel {
    z-index: 95;
    transition: all 0.2s linear;
    box-shadow: 3px 3px 17px 0 rgba(0, 0, 0, 0.17);
    position: sticky;
    top: 0;

    &__panelWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        background-color: palette('white');
        color: palette(carbonado, 900);
    }

    &__titleWrapper {
        display: flex;
        align-items: center;

        padding-left: 32px;

        & > a {
            display: flex;

            margin-right: 8px;
            cursor: pointer;

            &:hover {
                & > svg {
                    fill: palette('sapphire', 600);
                    transition: 0.3s;
                }
            }
        }
    }

    &__notification {
        background-color: palette(topaz, 200);
        padding: 16px;
    }

    &__link {
        color: palette(sapphire, 600);
    }

    &__demo {
        background-color: palette(emerald);
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        font-size: 12px;
        line-height: 2;
        color: #fff;
    }

    &__toggleMenu {
        display: none;
        margin-right: 8px;

        &:hover {
            opacity: 0.8;

            path {
                fill: palette(carbonado, 900);
            }
        }
    }

    &__logoWrapper {
        grid-template-columns: 56px 1fr;
        grid-gap: 16px;
        gap: 16px;
        align-items: center;
        display: none;
    }

    &__logo {
        padding: 12px;
        background-color: palette(ruby, 600);
        height: 32px;

        // for christmas logo
        // background-color: #fff;
        // height: 40px;
    }

    &__logoTitle {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: palette(carbonado, 900);
        font-family: $font-family-tt-firs;
    }

    &__userWrapper {
        display: flex;
        align-items: center;
        padding: 16px 24px;
    }

    &__user {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 24px;
    }

    &__logout {
        min-height: 0;

        &:hover {
            opacity: 0.8;
        }
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .TopPanel {
        width: 100vw;

        &__panelWrapper {
            justify-content: space-between;
        }

        &__toggleMenu {
            min-height: 0;
            display: block;
        }

        &__userWrapper {
            display: none;
        }

        &__logoWrapper {
            display: grid;
        }
    }
}
