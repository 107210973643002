@import 'style/global-config';

.aa-Tooltip {
    z-index: 110;
}

.aa-Popup__overlay_open,
.aa-Popup__containerWrapper {
    z-index: 100;
}

.CabinetContainer {
    background-color: $palette-noactive-7;
    display: flex;
}

.aa-Select__dropdownContent {
    z-index: 100;
}

.CabinetContent {
    width: 94%;
    background-color: $palette-noactive-7;
    box-sizing: border-box;
    transition: padding-left 0.2s linear;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media screen and (max-width: $mobile-breakpoint) {
        padding-left: 0;
    }

    &__body {
        padding: 24px 0;
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}

.Terms {
    // background-image: url('../../images/connect-background.jpg');
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &__block {
        width: 330px;
        display: flex;
        flex-direction: column;
    }
}

.TermsLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
